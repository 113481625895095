import React from 'react'
import styles from './AuthModal.module.scss'
import { Button } from '../Button'
import { AuthContainer } from './shared'

// Ported from ui-web-components

export interface ErrorModalProps {
  onConfirm: () => void
  title: string
  desc: string
  buttonText: string
  showHelp?: boolean
  showError?: boolean
}

export const AuthModal: React.FC<ErrorModalProps> = ({
  onConfirm,
  title,
  desc,
  buttonText,
  showHelp,
  showError,
}) => {
  return (
    <AuthContainer showError={showError}>
      <div className={styles.container}>
        <h1
          className={styles.title + (showError ? '' : ' ' + styles.blue)}
          data-test="error-modal-title"
        >
          {title}
        </h1>
        <p className={styles.errorDesc} data-test="error-modal-description">
          {desc}
        </p>
        {showHelp && (
          <p className={styles.subDesc} data-test="error-modal-contact-info">
            If you are using your school-assigned email address and still see
            this message, please visit our
            <a
              className={styles.contact}
              data-test="error-modal-contact-help-link"
              href="https://help.edwin.app/s/article/Solution-for-Error-300"
            >
              Help Centre.
            </a>
          </p>
        )}
        <Button
          className={styles.tryAgainButton}
          data-test="modal-confirm-button"
          onClick={(e) => {
            e.preventDefault()
            onConfirm()
          }}
          name="Confirm"
        >
          {buttonText}
        </Button>
      </div>
    </AuthContainer>
  )
}
