'use client'

import React, { useCallback, useEffect, useState } from 'react'
import Select, { ActionMeta, MultiValue } from 'react-select'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'
import { Option, UIProps } from '../types'
import { customStyles } from './MultiSelectDropdownStyles'

export const MS_PREFIX = 'ms'

export type MultiSelectDropdownProps = UIProps & {
  options: Option[]
  onChange: (selectedOptions: Option[]) => void
  onCreateOption?: (inputValue: string) => void
  placeholder?: string
  defaultValue?: Option[]
  classNamePrefix?: string
  minWidth?: string
}

export function MultiSelectDropdown({
  options,
  onChange,
  onCreateOption,
  placeholder = 'Select...',
  defaultValue = [],
  className,
  classNamePrefix = MS_PREFIX,
  minWidth,
}: MultiSelectDropdownProps) {
  const animatedComponents = makeAnimated()
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(defaultValue)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleChange = useCallback(
    (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
      const selectedOptions = Array.isArray(newValue) ? [...newValue] : []
      setSelectedOptions(selectedOptions)
      onChange(selectedOptions)
    },
    [onChange]
  )

  const handleCreateTag = useCallback(
    (inputValue: string) => {
      const newOption = { label: inputValue, value: inputValue }
      const newOptions = [newOption]
      setSelectedOptions((prevOptions) => [...prevOptions, ...newOptions])
      if (onCreateOption) {
        onCreateOption(inputValue)
      }
    },
    [onCreateOption]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        const activeElement = document.activeElement
        const isDropdownFocused = activeElement && activeElement.classList.contains(`${classNamePrefix}__input`)

        const dropdownMenu = document.querySelector(`.${classNamePrefix}__menu`) as HTMLElement | null
        const isDropdownOpen = dropdownMenu && dropdownMenu.offsetParent !== null

        if (isDropdownFocused && isDropdownOpen) {
          return
        }

        event.preventDefault()
      }
    },
    [classNamePrefix]
  )

  const handleBlur = useCallback((event: { stopPropagation: () => void }) => {
    event.stopPropagation()
  }, [])

  const SelectComponent = onCreateOption ? CreatableSelect : Select

  if (!isMounted) {
    return null
  }

  return (
    <div onBlur={handleBlur}>
      <SelectComponent
        isMulti
        styles={customStyles(minWidth)}
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        className={className}
        components={animatedComponents}
        onCreateOption={handleCreateTag}
        classNamePrefix={classNamePrefix}
        instanceId="collection-tags"
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}