import React, { SVGProps } from 'react'

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="#D8D8D8" strokeLinecap="square" strokeWidth="2">
      <path d="m1.417 1.417 13.806 13.806M15.583 1.417 1.777 15.223"></path>
    </g>
  </svg>
)
