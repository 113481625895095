export const FrMessages = {
  Yes: 'Oui',
  No: 'Non',
  Cancel: 'Annuler',
  Reset: 'Réinitialiser',
  Download: 'Télécharger',
  Saving: 'Enregistrement',
  Save_google_drive: 'Enregistrer sur Google Drive',
  Save_notes: 'Enregistrer dans vos notes',
  
  Auth_hello: 'Bonjour',
  Auth_back: 'retour',
  Auth_error: 'Erreur',
  Auth_or: 'ou',
  Auth_send: 'envoyer',
  Auth_sending: 'envoi en cours',
  Auth_continue: 'continuer',
  Auth_got_it: 'Compris',
  Auth_action_join: 'Joindre avec',
  Auth_action_signin: 'Se connecter avec',
  Auth_authenticating: "Authentification de l'utilisateur",
  Auth_different_email: 'un autre email',
  Auth_email: 'Entrez votre adresse email',
  Auth_email_not_found:
    "Nous n'avons trouvé aucun compte Edwin avec l'e-mail que vous avez fourni.",
  Auth_enterPassword: "s'il vous plait entrez votre mot de passe",
  Auth_error_contact_info:
    "Si vous utilisez l'adresse e-mail de votre école et que vous voyez toujours ce message, veuillez visitez notre ",
  Auth_error_contact_us: "centre d'aide.",
  Auth_error_info:
    "Assurez-vous de vous connecter en utilisant l'adresse e-mail de votre école.",
  Auth_error_info_inactive:
    "Votre licence Edwin a expiré. Veuillez renouveler votre licence ou contacter l'administrateur de votre école pour la restaurer.",
  Auth_error_info_license_inactive:
    "Votre licence Edwin n'est pas encore active. Veuillez contacter l'administrateur de votre école pour connaître la date à laquelle votre licence commence.",
  Auth_error_invalid_email: 'Compte Non Reconnu',
  Auth_error_invalid_email_format: 'Le format de l\'email est invalide',
  Auth_error_inactive_user: 'Licence Expirée',
  Auth_error_inactive_license: 'Licence Inactif',
  Auth_forgotPassword: 'Mot de passe oublié?',
  Auth_needHelp: "Besoin D'aide?",
  Auth_password_reset_link_expired:
    'Oh non! Le lien de réinitialisation du mot de passe a expiré',
  Auth_password_reset_link_expired2:
    'Les liens expirent après une heure. Pour obtenir un nouveau lien, cliquez sur le bouton ci-dessous.',
  Auth_password_reset_link_expired_button: 'Renvoyer le lien',
  Auth_requiredFieldIsEmpty: 'Un champ obligatoire est vide ou invalide!',
  Auth_resendEmail: "Ré-envoyer l'email",
  Auth_reset_password_link_sent:
    'Vérifiez votre boîte de réception, un email pour réinitialiser votre mot de passe a été envoyé!',
  Auth_returnLogin: 'retour à la connexion',
  Auth_send_reset_password_link:
    'Entrez votre email ci-dessous, nous vous enverrons un lien pour <strong>réinitialiser votre mot de passe:</strong>',
  Auth_sign_in_long:
    'Connectez-vous avec le compte de messagerie attribué à votre école',
  Auth_sign_in_long_docs:
    'Pour afficher ce document, connectez-vous avec le compte de messagerie attribué à votre école',
  Auth_sign_in_portal:
    'Connectez-vous avec le compte de messagerie attribué à votre école',
  Auth_sign_in_different_email: 'Connectez-vous avec une autre adresse e-mail',
  Auth_student_sign_up: 'Nouveaux étudiants -',
  Auth_student_sign_up2: 'Entrez votre code de classe',
  Auth_try_again: 'Essayer de nouveau',
  Auth_verifyEmail: 'Vérifier les courriels',
  Auth_verifyMessage: 'Vérifiez vos e-mails et cliquez sur le bouton',
  Auth_verifyEmailSent:
    'Nouveau mail envoyé. Vérifiez votre boîte de réception et cliquez sur le bouton',
  Auth_verifyEmailFailed: "Échec de l'envoi de l'e-mail. Veuillez réessayer.",
  Auth_welcome_to_edwin: 'Bonjour, bienvenue à',
  Auth_welcome_to_edwin_educator:
    'Bonjour, Bienvenue dans la communauté des éducateurs',
  Auth_welcome_to_edwin_educator2: '!',
  Auth_student_join:
    "Dernière étape, rejoindre avec votre e-mail attribué à l'école",
  Auth_student_use:
    'Vous utiliserez ce compte pour vous connecter à partir de maintenant',

  Whiteboard_tutorial_tools: 'Ajouter et faire glisser des éléments vers votre tableau blanc',
  Whiteboard_tutorial_tools_general: 'Faites glisser les éléments du panneau de gauche et organisez-les dans l\'espace de travail. En cliquant sur les éléments, vous pouvez les modifier et modifier ses propriétés.',
  Whiteboard_tutorial_tools_draw_and_erase: 'Dessiner/Effacer',
  Whiteboard_tutorial_tools_shapes: 'Formes',
  Whiteboard_tutorial_tools_UploadImage: 'Télécharger une image',
  Whiteboard_tutorial_tools_arrow_and_line: 'Flèche/Ligne',
  Whiteboard_tutorial_tools_text: 'Texte',
  Whiteboard_tutorial_tools_grid_and_background: 'Grille/Arrière-plan',
  Whiteboard_tutorial_tools_notes: 'Remarques',

  Whiteboard_tutorial_context_menu: 'Modifier les propriétés de l\'élément',

  Whiteboard_tutorial_actions_zoom_in_and_zoom_out: 'Zoomer/Dézoomer',
  Whiteboard_tutorial_actions_default_pointer: 'Pointeur par défaut',
  Whiteboard_tutorial_actions_drag_workspace: 'Faire glisser l\'espace de travail',
  Whiteboard_tutorial_actions_screenshot: 'Enregistrer une capture d\'écran du lieu du travail',
  Whiteboard_tutorial_actions_undo: 'Annuler',
  Whiteboard_tutorial_actions_redo: 'Refaire',
  Whiteboard_tutorial_actions_reset: 'Réinitialiser la toile',
  Whiteboard_tutorial_actions_trash: 'Faites glisser les objets ici pour supprimer',

  Whiteboard_reset: 'Cela va nettoyer la toile, comme une feuille de papier toute neuve. Prêt à recommencer?',
  Whiteboard_screenshot: 'Où voulez-vous enregistrer votre capture d\'écran?',
}
