'use client'

import {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import classnames from 'classnames'
import styles from './Checkbox.module.scss'
import { El, ElProps } from './El'

export type BaseCheckboxProps = ElProps & InputHTMLAttributes<HTMLInputElement> & {
  white?: boolean
  indeterminate?: boolean
}

export type LabelCheckBoxProps = BaseCheckboxProps & {
  label: string
  ariaLabel?: string
}

export type AriaLabelCheckBoxProps = BaseCheckboxProps & {
  label?: never
  ariaLabel: string
}

export type CheckboxProps = LabelCheckBoxProps | AriaLabelCheckBoxProps

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, label, ariaLabel, white, indeterminate, ...rest }, outerRef) => {
    const innerRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(outerRef, () => innerRef.current!, [])

    useEffect(() => {
      if (typeof indeterminate === 'boolean' && innerRef.current) {
        innerRef.current.indeterminate = !rest.checked && indeterminate
      }
    }, [innerRef, indeterminate])

    return (
      <El
        as={label ? 'label' : 'span'}
        className={classnames('checkbox-container', styles.container, className)}
      >
        <input
          ref={innerRef}
          className={classnames(
            styles.checkbox,
            white ? styles.white : undefined,
            label ? styles['has-label'] : undefined
          )}
          type="checkbox"
          aria-label={ariaLabel}
          {...rest}
        />
        {rest.checked &&
          <CheckMarkSVG />
        }
        {!rest.checked && indeterminate &&
          <IndeterminateSVG />
        }
        {label ? <span>{label}</span> : null}
      </El>
    )
  }
)
Checkbox.displayName = 'Checkbox'

export const CheckMarkSVG = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.38 1.98285C10.0099 1.72363 9.49977 1.81354 9.24059 2.18367L5.01671 8.2157L2.55947 6.49493C2.18936 6.23574 1.67917 6.32564 1.41993 6.69573C1.1607 7.06582 1.25059 7.57595 1.6207 7.83513L4.7483 10.0254C5.11842 10.2845 5.62861 10.1946 5.88784 9.82456C5.89053 9.82072 5.89317 9.81688 5.89578 9.81301L10.5808 3.12239C10.84 2.75226 10.7501 2.24207 10.38 1.98285Z"
      fill="#017ACC"
    />
  </svg>
)

export const IndeterminateSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <line
      x1="2.7"
      y1="5.3"
      x2="9.3"
      y2="5.3"
      stroke="#2A2A2A"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)
