'use client'

import React, { useState } from 'react'
import { Button } from '../Button'
import { Text } from '../Text'
import { AuthContainer, Welcome } from './shared'
import { Locale, Messages } from '../../messages'
import { getBrowserLanguage } from '../../messages'

export const ResendEmailModal: React.FC<{
  resendEmail: () => void
  goBack: () => void
  locale?: Locale
}> = ({ resendEmail, goBack, locale = getBrowserLanguage() }) => {
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleResendEmail = async () => {
    setError(false)
    if (emailSent) {
      goBack()
      return
    }
    try {
      setLoading(true)
      await resendEmail()
      setEmailSent(true)
    } catch (err) {
      setError(true)
    }
    setLoading(false)
  }

  const messages = Messages[locale]

  return (
    <AuthContainer showError={error}>
      <Welcome label={messages['Auth_welcome_to_edwin']} />
      <Text
        data-test="resend-email-verify-msg"
        textAlign="center"
        mt={4}
        mb={8}
      >
        {
          messages[
            emailSent
              ? 'Auth_verifyEmailSent'
              : error
                ? 'Auth_verifyEmailFailed'
                : 'Auth_verifyMessage'
          ]
        }{' '}
        {!error && <Text fontWeight={700}>{messages['Auth_verifyEmail']}</Text>}
      </Text>
      <Button
        data-test="resend-email-submit-button"
        onClick={emailSent ? goBack : handleResendEmail}
        size="md"
        mb={5}
        disabled={loading}
      >
        {
          messages[
            loading
              ? 'Auth_sending'
              : emailSent
                ? 'Auth_got_it'
                : 'Auth_resendEmail'
          ]
        }
      </Button>
      <Button
        data-test="resend-email-return-to-login-button"
        onClick={goBack}
        variant="ghost"
        size="sm"
        active
      >
        {messages['Auth_returnLogin']}
      </Button>
    </AuthContainer>
  )
}
