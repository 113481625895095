'use client' // TODO: isolate useRef so inner components can be rendered on server
import React from 'react'

// Next.js-specific imports
import Link from 'next/link'

// import { CSSTransition } from 'react-transition-group'
import { AnimatePresence, motion } from 'framer-motion'
import classnames from 'classnames'
import { Button } from './Button'
import './transitions.css'
import styles from './Menu.module.scss'

export type MenuOption<T> = {
  label: React.ReactNode
  value: T | undefined
  route?: string
}

export type MenuDirection = 'down' | 'up'

// TODO: fix framer-motion when imported to next-core
export const Menu = ({
  open,
  children,
  title,
  direction = 'down',
  maxHeight,
}: {
  open: boolean
  children: React.ReactNode
  title?: React.ReactNode
  direction?: MenuDirection
  maxHeight?: number
}) => {
  const isUp = direction === 'up'

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          className={classnames(
            'menu',
            styles.menu,
            isUp ? styles.up : undefined,
          )}
        >
          {title
            ? (
              <div className={classnames('menu-title', styles['menu-title'])}>
                {title}
              </div>
            )
            : null
          }

          <div data-test="dropdown-list-div" style={{ maxHeight }} className={classnames('menu-content', styles['menu-content'])}>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export function MenuGroup<T>({
  title,
  options,
  selected,
  handleSelect,
}: {
  title: React.ReactNode
  options: MenuOption<T>[]
  selected?: T | T[]
  handleSelect: (value?: T) => void
}) {
  return (
    <div className={classnames('menu-group', styles['menu-group'])}>
      <div className="menu-group-title">{title}</div>
      {options.map((option) => (
        <MenuItem
          key={`${option.label}-${option.value}`}
          option={option}
          selected={selected}
          handleSelect={handleSelect}
        />
      ))}
    </div>
  )
}

export function MenuItem<T>({
  option,
  selected,
  handleSelect,
}: {
  option: MenuOption<T>
  selected?: T | T[]
  handleSelect: (value?: T) => void
}) {
  const active = Array.isArray(selected)
    ? Boolean(selected.find((val) => val === option.value))
    : selected === option.value

  const button = <Button
    key={`${option.label}-${option.value}`}
    type="button"
    data-test="menu-item-button"
    onClick={() => handleSelect(option.value)}
    active={active}
    variant="ghost"
    size="sm"
  >
    {option.label}
  </Button>

  if (option.route) {
    return <Link href={option.route}>{button}</Link>
  }

  return button
}

export const MenuDivider = () => <div className={styles.divider} />
