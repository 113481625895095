import { EnMessages } from './en'
import { FrMessages } from './fr'

export enum Locale {
  EN = 'EN',
  FR = 'FR',
}

/**
 * LOCALES is structured in a way to make conversion between navigator.language and the format for a ReactSelect seamless.
 * navigator.language returns values such as ['en', 'en-US', 'fr', 'fr-CA', ...] which will map to the keys of the
 * LOCALES object. The redundant `LOCALES[key].value === key` is what makes ReactSelect work seamlessly.
 */
export const LOCALES: Record<Locale, { label: string; value: Locale }> = {
  [Locale.EN]: {
    label: 'English',
    value: Locale.EN,
  },
  [Locale.FR]: {
    label: 'Française',
    value: Locale.FR,
  },
}

export const getBrowserLanguage = () => {
  if (typeof window === 'undefined') {
    return Locale.EN
  }
  
  if (!window.navigator) {
    return Locale.EN
  }

  if (typeof window.navigator.language !== 'string') {
    return Locale.EN
  }

  const lang = window.navigator.language.slice(0, 2).toUpperCase()

  if (
    Object.values(LOCALES).some((supportedLang) => supportedLang.value === lang)
  ) {
    return lang as Locale
  }
  
  return Locale.EN
}

export const Messages = {
  [Locale.EN]: EnMessages,
  [Locale.FR]: FrMessages,
}
