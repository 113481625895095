'use client'

import React, {
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ElProps } from './El'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import throttle from 'lodash-es/throttle'
import { useMergeRefs } from './useMergeRefs'
import styles from './HoverText.module.scss'
import { Placement } from '@floating-ui/react'

export type HoverTextProps = ElProps &
HTMLAttributes<HTMLElement> & {
  children: ReactNode
  root?: HTMLElement | null | MutableRefObject<HTMLElement | null> // FloatingPortalProps['root'] (not exported from lib)
  placement?: Placement
}

/*
  Results will be volatile if `children` is not plain text.

  For use with single-line overflow text (like a line-item in a table). If it's
  known the text will be multi-line, then use `LineClampedText`.
*/
export const HoverText = forwardRef<HTMLElement, HoverTextProps>(
  (ownProps, propsRef) => {
    const { root, children, placement, ...rest } = ownProps
    const innerRef = useRef<HTMLElement>(null)

    const [isOverflowing, setIsOverflowing] = useState(false)
    const determineOverflow = useCallback(() => {
      if (!innerRef.current) {
        return
      }

      const { scrollWidth, clientWidth, scrollHeight, clientHeight } =
        innerRef.current

      if (scrollWidth > clientWidth || scrollHeight > clientHeight) {
        setIsOverflowing(true)
        return
      }

      setIsOverflowing(false)
    }, [innerRef])

    useEffect(() => {
      determineOverflow()

      const throttledResizeHandler = throttle(() => determineOverflow(), 500)
      window.addEventListener('resize', throttledResizeHandler)

      return () => {
        window.removeEventListener('resize', throttledResizeHandler)
      }
    }, [determineOverflow])

    const ref = useMergeRefs(propsRef, innerRef)

    if (!isOverflowing) {
      return (
        <span ref={ref} className={styles.content} {...rest}>
          {children}
        </span>
      )
    }

    return (
      <Popover placement={placement}>
        <PopoverTrigger {...rest} ref={ref} className={styles.content}>
          {children}
        </PopoverTrigger>
        <PopoverContent root={root}>{children}</PopoverContent>
      </Popover>
    )
  }
)
HoverText.displayName = 'HoverText'
