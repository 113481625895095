'use client'

import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styles from './LineClampedText.module.scss'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import throttle from 'lodash-es/throttle'
import { Placement } from '@floating-ui/react'

/*
  Results will be volatile if `children` is not plain text.

  For use with multi-line clamped text. If it's known that the text will be a single
  line (like a line-item in a table) then use `HoverText`.

  Use withHover=true to create a hover state that shows the full text when the
  text overflows. To meet requirements of WCAG text-spacing, we need to do this
  when the text is not a link to another page where that text is displayed.
  i.e. a paragraph that is cut off needs to use withHover=true, while a link that
  is a title of a page that links to that page where the title is displayed does
  not need withHover.
 */
export const LineClampedText: FC<
  PropsWithChildren<{ showAllOnHover?: boolean; placement?: Placement, lines?: number }>
> = ({ children, showAllOnHover = false, placement, lines }) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [lineClamp, setLineClamp] = useState<number>(lines || 3)
  const [height, setHeight] = useState<number | null>()

  const calculateLineClamp = useCallback(() => {
    const el = ref.current

    if (!el || el.scrollHeight <= el.clientHeight) {
      setHeight(null)
      return
    }

    const computedLineHeight = window.getComputedStyle(el).lineHeight
    if (computedLineHeight === 'normal') {
      // TODO: calculate body lineHeight and use that if using defaults
      setHeight(null)
      return
    }

    const lineHeight: number = parseInt(computedLineHeight.slice(0, -2))
    const lines = Math.floor(el.clientHeight / lineHeight)
    const height = lines * lineHeight

    setLineClamp(lines)
    setHeight(height)
  }, [ref, setLineClamp, setHeight])

  useEffect(() => {
    calculateLineClamp()

    const throttledResizeHandler = throttle(() => calculateLineClamp(), 500)
    window.addEventListener('resize', throttledResizeHandler)

    return () => {
      window.removeEventListener('resize', throttledResizeHandler)
    }
  }, [calculateLineClamp])

  /*
    Many accessibility extensions inject css into the DOM, which may or may not
    happen after the component is rendered and lineClamp is calculated. So for
    accessibility, this uses a mutation observer to detect attribute changes and
    recalculate the lineClamp.
   */
  const cb: MutationCallback = useCallback(
    (mutationList, _observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          calculateLineClamp()
        }
      }
    },
    [calculateLineClamp]
  )

  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(cb)
      observer.observe(ref.current, {
        attributes: true,
        childList: false,
        subtree: false,
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [cb, ref])

  const spanProps = {
    ref,
    style: {
      WebkitLineClamp: lineClamp,
      height: height ? height + 'px' : '100%',
    },
    className: styles.clamped,
  }

  if (showAllOnHover) {
    return (
      <Popover placement={placement}>
        <PopoverTrigger>
          <span {...spanProps}>{children}</span>
        </PopoverTrigger>
        {/* height is null if the text is not line-clamped */}
        {height && <PopoverContent>{children}</PopoverContent>}
      </Popover>
    )
  }

  return <span {...spanProps}>{children}</span>
}
