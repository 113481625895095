'use client'

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styles from './LoginModal.module.scss'
import { Flex } from '../Flex'
import { Spinner } from '../Spinner'
import { Button } from '../Button'
import { Text } from '../Text'
import { AuthContainer, Welcome } from './shared'
import { emailRegex } from '../../util'
import { getBrowserLanguage, Locale, Messages } from '../../messages'

export interface LoginModalProps {
  onEmailLogin: (name: boolean) => void
  onProviderLogin: (display: string) => void
  onSubmit: (data: { email: string }) => void
  showSpinner?: boolean
  showEmailField?: boolean
  mode?: string
  footer?: React.ReactNode
  locale?: Locale
}

export const LoginModal: React.FC<LoginModalProps> = ({
  onEmailLogin,
  onProviderLogin,
  onSubmit,
  showSpinner,
  showEmailField,
  mode,
  footer,
  locale = getBrowserLanguage(),
}: LoginModalProps) => {
  const [didSubmitEmail, setDidSubmitEmail] = useState(false)
  const [displayContinue, setDisplayContinue] = useState(false)

  const {
    watch,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>()

  let email = watch('email')
  email = email && email.trim()
  const invalidEmail = (email && !emailRegex.test(email)) || false

  useEffect(() => {
    clearErrors()
    setDidSubmitEmail(false)
    if (email && email?.length) {
      setDisplayContinue(true)
    } else {
      console.warn('provided email is not well-formed')
      setDisplayContinue(false)
    }
  }, [email])

  useEffect(() => {
    clearErrors()
    setDidSubmitEmail(false)
  }, [])

  const showSpinnerAndLoginWith = async (
    providerName: string
  ): Promise<void> => {
    onProviderLogin(providerName)
  }

  const displayEmailFieldContainer = async (
    displayEmailField: boolean
  ): Promise<void> => {
    onEmailLogin(displayEmailField)
  }

  const messages = Messages[locale]
  const studentMode = mode === 'student'
  const loginAction = studentMode
    ? messages['Auth_action_join']
    : messages['Auth_action_signin']

  return (
    <AuthContainer>
      <div>
        {!studentMode && <Welcome label={messages['Auth_welcome_to_edwin']} />}
        <div className={styles.signInInfo}>
          {showSpinner ? (
            <>
              <Spinner data-test="login-modal-spinner" />
              <div
                className={styles.spinnerText}
                data-test="login-modal-auth-text"
              >
                {messages['Auth_authenticating']}
              </div>
            </>
          ) : !studentMode ? (
            <div className={styles.welcomeDesc}>
              {
                messages[
                  mode === 'document'
                    ? 'Auth_sign_in_long_docs'
                    : 'Auth_sign_in_portal'
                ]
              }
            </div>
          ) : (
            <Text variant="large" fontWeight={700}>
              {messages['Auth_student_join']}
            </Text>
          )}
        </div>
      </div>
      <Flex direction="column" justifyContent="center" gap={4} my={3}>
        <Button
          type="button"
          onClick={() => showSpinnerAndLoginWith('google')}
          name={`${loginAction} Google`}
          data-test="login-modal-google-button"
        >
          {GoogleIcon}
          <Text data-test="login-modal-google-text" fontWeight={700} ml={2}>
            {loginAction} Google
          </Text>
        </Button>
        <Button
          type="button"
          onClick={() => showSpinnerAndLoginWith('microsoft')}
          name={`${loginAction} Microsoft`}
          data-test="login-modal-microsoft-button"
        >
          {MicrosoftIcon}
          <Text data-test="login-modal-microsoft-text" fontWeight={700} ml={2}>
            {loginAction} Microsoft
          </Text>
        </Button>
      </Flex>

      <form
        className={styles.bottomRow}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit)()
        }}
      >
        <Flex alignItems="center">
          <div className={styles.orRow} />
          <span
            className={styles.orRowText}
            data-test="login-modal-hr-line-or"
          >
            {messages['Auth_or']}
          </span>
          <div className={styles.orRow} />
        </Flex>

        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={4}
          my={3}
        >
          {showEmailField ? (
            <div className={styles.displayEmailFieldContainer}>
              <input
                className={styles.authInput}
                aria-label="Enter email"
                {...register('email', {
                  required: messages['Auth_email'],
                  pattern: emailRegex,
                })}
                placeholder={messages['Auth_email']}
                data-test="login-modal-manual-email-input"
              />

              {invalidEmail && !errors.email && (
                <div className={styles.invalidEmail}>
                  {messages['Auth_error_invalid_email_format']}
                </div>
              )}
              {errors.email && errors.email.message}

              {didSubmitEmail ? (
                <>
                  <Spinner data-test="login-modal-submit-spinner" />
                  <div className={styles.spinnerText}>
                    {messages['Auth_authenticating']}
                  </div>
                </>
              ) : displayContinue ? (
                <Button
                  className={styles.continueButton}
                  type="submit"
                  disabled={invalidEmail}
                  data-test="login-modal-submit-button"
                >
                  {messages['Auth_continue']}
                </Button>
              ) : (
                <div className={styles.margin} />
              )}
            </div>
          ) : (
            <Button
              type="button"
              className={styles.signInWithEmail}
              aria-label="Sign In with a different email"
              variant="ghost"
              mt={1}
              mb={2}
              onClick={() => displayEmailFieldContainer(true)}
              data-test="login-modal-different-email-button"
            >
              {loginAction} {messages['Auth_different_email']}
            </Button>
          )}

          {showEmailField && !invalidEmail && <Button type="submit" data-test="login-modal-continue-button" >continue</Button>}

          {studentMode ? (
            <Text color="primary" mb={6} textAlign="center">
              <>{messages['Auth_student_use']}</>
            </Text>
          ) : (
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                window.location.href = '/join'
              }}
              variant="secondary"
              data-test="login-modal-student-join-button"
            >
              {messages['Auth_student_sign_up']}{' '}
              {messages['Auth_student_sign_up2']}
            </Button>
          )}

          <a
            className={styles.helpLink}
            href="https://help.edwin.app/s/article/Sign-in-to-Edwin"
            target="_blank"
            data-test="login-modal-help-link"
          >
            {messages['Auth_needHelp']}
          </a>
        </Flex>

        {footer}
      </form>
    </AuthContainer>
  )
}

const GoogleIcon = (
  <svg
    viewBox="0 0 533.5 544.3"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
  >
    <path
      d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
      fill="#4285f4"
    />
    <path
      d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
      fill="#34a853"
    />
    <path
      d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
      fill="#fbbc04"
    />
    <path
      d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
      fill="#ea4335"
    />
  </svg>
)

const MicrosoftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width="18"
    height="18"
  >
    <path fill="#f3f3f3" d="M0 0h23v23H0z" />
    <path fill="#f35325" d="M1 1h10v10H1z" />
    <path fill="#81bc06" d="M12 1h10v10H12z" />
    <path fill="#05a6f0" d="M1 12h10v10H1z" />
    <path fill="#ffba08" d="M12 12h10v10H12z" />
  </svg>
)
