'use client'

import React, {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react'
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import classnames from 'classnames'
import { ElProps } from './El'
import styles from './Tooltip.module.scss'
import { ReactRef, useMergeRefs } from './useMergeRefs'

const ARROW_WIDTH = 20
const ARROW_HEIGHT = 5

export type Placement = 'top' | 'right' | 'bottom' | 'left'

export type TooltipProps = ElProps &
HTMLAttributes<HTMLElement> & {
  label: string
  placement?: Placement
  shouldWrapChildren?: boolean
  className?: string
  root?: HTMLElement | null | MutableRefObject<HTMLElement | null> // FloatingPortalProps['root'] (not exported from lib)
  children?: ReactNode | undefined
}

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  (ownProps, propRef) => {
    const {
      label,
      placement = 'bottom',
      shouldWrapChildren,
      root,
      children,
      className,
      ...rest
    } = ownProps

    const [isOpen, setIsOpen] = useState(false)
    const arrowRef = useRef(null)

    const shouldWrap = typeof children === 'string' || shouldWrapChildren

    let trigger: React.ReactElement
    // floating-ui
    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      placement,
      middleware: [offset(10), flip(), shift(), arrow({ element: arrowRef })],
      // Make sure the tooltip stays on the screen
      whileElementsMounted: autoUpdate,
    })

    const hover = useHover(context, {
      move: false,
      restMs: 400,
      delay: { open: 1000, close: 400 },
      handleClose: safePolygon(),
    })
    const focus = useFocus(context)
    const dismiss = useDismiss(context)
    const role = useRole(context, { role: 'tooltip' })

    const { getReferenceProps, getFloatingProps } = useInteractions([
      hover,
      focus,
      dismiss,
      role,
    ])

    const { isMounted, styles: transitionStyles } = useTransitionStyles(
      context,
      {
        initial: ({ side }) => ({
          opacity: 0,
          transform: {
            top: 'translateY(20%)',
            bottom: 'translateY(-20%)',
            left: 'translateX(20px)',
            right: 'translateX(-20px)',
          }[side],
        }),
        common: {
          opacity: 1,
          transform: 'translateY(0) translateX(0)',
        },
        duration: 200,
      }
    )

    const child = React.Children.only(children)
    let childrenRef: ReactRef<any> | undefined
    childrenRef = (child as ReactElement)?.props?.ref
    const ref = useMergeRefs(refs.setReference, propRef, childrenRef)

    if (shouldWrap) {
      trigger = (
        <span tabIndex={0} ref={ref} {...getReferenceProps(rest)}>
          {children}
        </span>
      )
    } else {
      const child = Children.only(children) as React.ReactElement
      trigger = cloneElement(
        child,
        getReferenceProps({
          ref,
          ...rest,
          ...child.props,
        })
      )
    }

    return (
      <>
        {trigger}
        <FloatingPortal root={root}>
          {isMounted && (
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div
                role="tooltip"
                style={{ ...transitionStyles, zIndex: 9999999 }}
                className={classnames(
                  styles.tooltip,
                  styles[placement],
                  className
                )}
              >
                {label}
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  width={ARROW_WIDTH}
                  height={ARROW_HEIGHT}
                  fill="#4a4a4a" // $color-neutral-400
                  stroke="#4a4a4a" // $color-neutral-400
                  tipRadius={1}
                />
              </div>
            </div>
          )}
        </FloatingPortal>
      </>
    )
  }
)
Tooltip.displayName = 'Tooltip'
