import { StylesConfig } from 'react-select'
import { Option } from '../types'

export const customStyles = (minWidth?: string): StylesConfig<Option, true> => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    border: '1px solid #017ACC',
    borderRadius: '40px',
    minHeight: '40px',
    minWidth: minWidth || '100%',
    display: 'inline-flex',
    alignItems: 'center',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    '&:hover': {
      border: '1px solid #017ACC',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: 'center',
    color: 'rgb(74, 74, 74)',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#017ACC',
    display: 'flex',
    alignItems: 'center',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#017ACC',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#017ACC',
      color: 'white',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: '4px',
    background: '#E1E1E1',
    display: 'inline-flex',
    alignItems: 'center',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#2A2A2A',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.3px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#017ACC',
    '&:hover': {
      backgroundColor: '#017ACC',
      color: 'white',
    },
  }),
  option: (provided, state) => {
    let backgroundColor = 'white'
    let color = 'rgb(74, 74, 74)'

    if (state.isSelected) {
      backgroundColor = 'rgb(74, 74, 74)'
      color = 'white'
    }
    if (state.isFocused) {
      backgroundColor = '#007ACC'
      color = 'white'
    }

    return {
      ...provided,
      backgroundColor,
      color,
      '&:hover': {
        backgroundColor: '#007ACC',
        color: 'white',
      },
      '&:focus': {
        backgroundColor: '#007ACC',
        color: 'white',
      },
    }
  },
})
