export const EnMessages = {
  Yes: 'Yes',
  No: 'No',
  Cancel: 'Cancel',
  Reset: 'Reset',
  Download: 'Download',
  Saving: 'Saving',
  Save_google_drive: 'Save to Google Drive',
  Save_notes: 'Save to Your Notes',
  
  Auth_hello: 'Hello',
  Auth_back: 'back',
  Auth_error: 'Error',
  Auth_or: 'or',
  Auth_send: 'send',
  Auth_sending: 'sending',
  Auth_continue: 'continue',
  Auth_got_it: 'Got it',
  Auth_action_join: 'Join with',
  Auth_action_signin: 'Sign in with',
  Auth_authenticating: 'Authenticating user',
  Auth_different_email: 'a different email',
  Auth_email: 'Please enter your email address',
  Auth_email_not_found:
    'We couldn’t find any Edwin account with the email you provided.',
  Auth_enterPassword: 'Please enter your password',
  Auth_error_contact_info:
    'If you are using your school-assigned email address and still see this message, please visit our ',
  Auth_error_contact_us: 'help centre.',
  Auth_error_info:
    'Please make sure to sign in using your school-assigned email address.',
  Auth_error_info_inactive:
    'Your Edwin license has expired. Please renew your license, or contact your school administrator to restore it.',
  Auth_error_info_license_inactive:
    'Your Edwin license is not yet active. Please contact your school administrator to find out the date that your license begins.',
  Auth_error_invalid_email: 'Account Not Recognized',
  Auth_error_invalid_email_format: 'Email format is invalid',
  Auth_error_inactive_user: 'License Expired',
  Auth_error_inactive_license: 'Inactive License',
  Auth_forgotPassword: 'Forgot Password?',
  Auth_needHelp: 'Need Help?',
  Auth_password_reset_link_expired:
    'Oh no! The password reset link has expired',
  Auth_password_reset_link_expired2:
    'Links expire after one hour. To get a new link, click on the button below.',
  Auth_password_reset_link_expired_button: 'Resend Link',
  Auth_requiredFieldIsEmpty: 'A required field is empty or invalid!',
  Auth_resendEmail: 'Resend email',
  Auth_reset_password_link_sent:
    'Check your inbox, an email to reset your password has been sent!',
  Auth_returnLogin: 'return to login',
  Auth_send_reset_password_link:
    'Enter your email below, we’ll send you a link to <strong>reset your password:</strong>',
  Auth_sign_in_long: 'Sign in with your school assigned email account',
  Auth_sign_in_long_docs:
    'To view this document, sign in with your school assigned email account',
  Auth_sign_in_portal: 'Sign in with your school-assigned email account',
  Auth_sign_in_different_email: 'Sign In with a different email',
  Auth_student_sign_up: 'New Students - ',
  Auth_student_sign_up2: 'Enter your class code',
  Auth_try_again: 'Try again',
  Auth_verifyEmail: 'Verify Email',
  Auth_verifyMessage: 'Check your email and click on the button',
  Auth_verifyEmailSent:
    'New email sent. Check your inbox and click on the button',
  Auth_verifyEmailFailed: 'Email failed to send. Please try again.',
  Auth_welcome_to_edwin: 'Hi, Welcome to',
  Auth_welcome_to_edwin_educator: 'Hi, Welcome to the',
  Auth_welcome_to_edwin_educator2: ' educator community!',
  Auth_student_join: 'Last step, join with your school-assigned email',
  Auth_student_use: 'You will use this account to sign-in from now on',

  Whiteboard_tutorial_tools: 'Add and drag elements to your whiteboard',
  Whiteboard_tutorial_tools_general: 'Drag elements from the left panel and arrange them in the workspace. Click on an element to edit it and change its properties.',
  Whiteboard_tutorial_tools_draw_and_erase: 'Draw/Erase',
  Whiteboard_tutorial_tools_shapes: 'Shapes',
  Whiteboard_tutorial_tools_UploadImage: 'Upload Image',
  Whiteboard_tutorial_tools_arrow_and_line: 'Arrow/Line',
  Whiteboard_tutorial_tools_text: 'Text',
  Whiteboard_tutorial_tools_grid_and_background: 'Grid/Background',
  Whiteboard_tutorial_tools_notes: 'Notes',

  Whiteboard_tutorial_context_menu: 'Edit the element properties',

  Whiteboard_tutorial_actions_zoom_in_and_zoom_out: 'Zoom in/Zoom out',
  Whiteboard_tutorial_actions_default_pointer: 'Default Pointer',
  Whiteboard_tutorial_actions_drag_workspace: 'Drag workspace',
  Whiteboard_tutorial_actions_screenshot: 'Save a screenshot of the workspace',
  Whiteboard_tutorial_actions_undo: 'Undo',
  Whiteboard_tutorial_actions_redo: 'Redo',
  Whiteboard_tutorial_actions_reset: 'Reset the canvas',
  Whiteboard_tutorial_actions_trash: 'Drag objects here to delete',

  Whiteboard_reset: 'This will wipe the canvas clean, like a fresh sheet of paper. Ready to start over?',
  Whiteboard_screenshot: 'Where do you want to save your screenshot?',
}
