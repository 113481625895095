'use client'

import { CloseIcon } from 'next-core/src/components/SVG/CloseIcon'
import React, {
  createContext,
  FC,
  forwardRef,
  MutableRefObject,
  PropsWithChildren, useCallback,
  useContext,
  useState,
} from 'react'
import {
  autoUpdate,
  ExtendedRefs,
  FloatingPortal,
  offset,
  OffsetOptions,
  Placement,
  ReferenceType,
  safePolygon,
  shift, useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles, UseTransitionStylesProps,
} from '@floating-ui/react'
import classnames from 'classnames'
import { Button } from './Button'
import styles from './Popover.module.scss'
import { useMergeRefs } from './useMergeRefs'

export type PopoverContextType<RT> = {
  refs: ExtendedRefs<RT>
  floatingStyles: React.CSSProperties
  getReferenceProps: (
    userProps?: React.HTMLProps<Element> | undefined
  ) => Record<string, unknown>
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>
  isMounted: boolean
  transitionStyles: React.CSSProperties
  placement: Placement
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  useCloseButton: boolean
}

export type PopoverProps = {
  placement?: Placement
  interactionType?: 'hover' | 'click'
  useCloseButton?: boolean
  useTransform?: boolean
  transitionStyleProps?: UseTransitionStylesProps
  onOpenChange?: (newState: boolean) => void
}

export const PopoverContext =
  createContext<PopoverContextType<ReferenceType> | null>(null)

export const Popover: FC<PropsWithChildren<PopoverProps>> = ({
  children,
  placement = 'top',
  interactionType = 'hover',
  useCloseButton = false,
  transitionStyleProps,
  onOpenChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpenChange = useCallback((newState: boolean) => {
    if (onOpenChange) {
      onOpenChange(newState)
    }
    setIsOpen(newState)
  }, [onOpenChange])

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: handleOpenChange,
    placement,
    middleware: [
      // we need custom placements as we are floating 'within' the element and not 'around' the outside (which is what the default 'placement' property does)
      offset(({ rects }) => {
        const returnVal: OffsetOptions = {}

        if (placement.startsWith('top') || placement.startsWith('bottom')) {
          returnVal.mainAxis = -rects.floating.height
        }
        if (placement.startsWith('left') || placement.startsWith('right')) {
          returnVal.mainAxis = -rects.floating.width
        }

        return returnVal
      }),
      shift({ crossAxis: true, padding: 12 }),
    ],
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    enabled: interactionType === 'hover',
    move: false,
    restMs: 400,
    delay: { open: 1000, close: 400 },
    handleClose: safePolygon(),
  })
  const click = useClick(context, {
    enabled: interactionType === 'click',
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = [
    hover,
    click,
    focus,
    dismiss,
    role,
  ]
  const { getReferenceProps, getFloatingProps } = useInteractions(interactions)

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, transitionStyleProps ?? {
    initial: ({ side }) => ({
      opacity: 0,
      transform: {
        top: 'translateY(20%)',
        bottom: 'translateY(-20%)',
        left: 'translateX(20px)',
        right: 'translateX(-20px)',
      }[side],
    }),
    common: {
      opacity: 1,
      transform: 'translateY(-100%) translateX(0)',
    },
    duration: 200,
  })

  return (
    <PopoverContext.Provider
      value={{
        getReferenceProps,
        refs,
        floatingStyles,
        getFloatingProps,
        isMounted,
        transitionStyles,
        placement,
        setIsOpen,
        useCloseButton,
      }}
    >
      {children}
    </PopoverContext.Provider>
  )
}

export const PopoverTrigger = forwardRef<
  HTMLElement,
  PropsWithChildren<{ className?: string }>
>((props, propsRef) => {
  const { children, ...rest } = props

  const context = useContext(PopoverContext)
  if (!context) {
    throw new Error('<PopoverTrigger> component must be a child of <Popover>')
  }

  const { refs, getReferenceProps } = context
  const ref = useMergeRefs(propsRef, refs.setReference)

  return (
    <span tabIndex={0} {...getReferenceProps(rest)} ref={ref} data-test="popover-trigger">
      {children}
    </span>
  )
})
PopoverTrigger.displayName = 'PopoverTrigger'

export const PopoverContent: FC<
  PropsWithChildren<{
    className?: string
    containerClassName?: string
    containerStyle?: React.CSSProperties
    style?: React.CSSProperties
    root?: HTMLElement | null | MutableRefObject<HTMLElement | null> // FloatingPortalProps['root'] (not exported from lib)
  }>
> = ({ children, className, containerClassName, containerStyle, root, style }) => {
  const context = useContext(PopoverContext)
  if (!context) {
    throw new Error('<PopoverContent> component must be a child of <Popover>')
  }

  const {
    refs,
    floatingStyles,
    getFloatingProps,
    isMounted,
    transitionStyles,
    setIsOpen,
    useCloseButton,
  } = context

  return (
    <FloatingPortal root={root}>
      {isMounted && (
        <div
          ref={refs.setFloating}
          style={{ zIndex: 20, ...containerStyle, ...floatingStyles }}
          className={containerClassName}
          {...getFloatingProps()}
        >
          <div
            role="tooltip"
            style={{ ...style, ...transitionStyles }}
            className={classnames(styles.popover, className)}
          >
            {children}

            {useCloseButton &&
              <Button
                variant={'unstyled'}
                className={styles.closeButton}
                aria-label="Close modal"
                onClick={() => setIsOpen(false)}
                data-test="popover-close-button"
              >
                <CloseIcon className={styles.closeButton} />
              </Button>
            }
          </div>
        </div>
      )}
    </FloatingPortal>
  )
}
