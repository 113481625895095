// TODO: isolate useRef so inner components can be rendered on server
'use client'

import classnames from 'classnames'
// React-specific import
import React, { useRef, useState } from 'react'
import { DropdownOptions, UIProps } from '../types'
import { Button, ButtonProps } from './Button'
import styles from './Dropdown.module.scss'
import { Menu, MenuDirection, MenuDivider, MenuGroup, MenuItem } from './Menu'
import { useOutsideClick } from './useOutsideClick'

export type DropdownProps<T> = UIProps & {
  selected?: T | T[]
  selectedIndicator?: boolean
  options: DropdownOptions<T>
  onSelect: (value?: T) => void
  title?: React.ReactNode
  variant?: ButtonProps['variant']
  enableNone?: boolean
  noneLabel?: string
  buttonProps?: Omit<ButtonProps, 'rightIcon'>
  direction?: MenuDirection
  emptyState?: React.ReactNode
  maxHeight?: number
}

export function Dropdown<T>({
  className,
  selected,
  selectedIndicator = false,
  options,
  onSelect,
  title,
  variant = 'primary',
  enableNone,
  noneLabel,
  buttonProps = {},
  direction = 'down',
  emptyState,
  children,
  maxHeight,
  ...props
}: DropdownProps<T>) {
  const [open, setOpen] = useState(false)

  const toggleDropdown = () => setOpen(!open)

  const containerRef = useRef<HTMLDivElement>(null)

  const isMulti = Array.isArray(selected)

  const closeDropdown = () => setOpen(false)

  const handleSelect = (value?: T) => {
    onSelect(value)

    if (!isMulti) {
      closeDropdown()
    }
  }

  useOutsideClick(containerRef, closeDropdown)

  const c = classnames(
    styles.button,
    styles[variant],
    open
      ? styles.open
      : undefined,
    selectedIndicator && selected
      ? styles.selected
      : undefined,
  )

  return (
    <div ref={containerRef} className={classnames(styles.container, className, { [styles.open]: open })} {...props}>
      <Button
        type="button"
        className={c}
        onClick={toggleDropdown}
        rightIcon={<DropdownChevron />}
        variant={variantMap[variant]}
        active={open}
        size="sm"
        {...buttonProps}
      >
        {children}
      </Button>

      <Menu open={open} title={title} direction={direction} maxHeight={maxHeight}>
        {options.length === 0 && emptyState}
        {options.map((option, i) => {
          if ('section' in option) {
            return (
              <MenuGroup
                key={`menu-group-${option.section}`}
                title={option.section}
                options={option.data}
                selected={selected}
                handleSelect={handleSelect}
              />
            )
          }

          if ('divider' in option) {
            return <MenuDivider key={`divider-${i}`} />
          }

          return (
            <MenuItem
              key={`${option.label}-${option.value}`}
              option={option}
              selected={selected}
              handleSelect={handleSelect}
            />
          )
        })}

        {enableNone && (
          <>
            <MenuDivider />

            <MenuItem
              option={{ label: noneLabel ? noneLabel : 'None', value: undefined }}
              selected={selected}
              handleSelect={handleSelect}
            />
          </>
        )}
      </Menu>
    </div>
  )
}

const variantMap: Record<string, ButtonProps['variant']> = {
  primary: 'ghost',
  secondary: 'secondary',
  tertiary: 'tertiary',
}

export const DropdownChevron = () => (
  <svg
    width="15.28"
    height="6.43"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66699 1.66669L9.23047 8.09431L16.9448 1.66669"
      stroke="#2A2A2A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
