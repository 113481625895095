'use client' // TODO: isolate useRef so inner components can be rendered on server
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
// import { CSSTransition } from 'react-transition-group'
// import { CSSTransitionProps } from 'react-transition-group/CSSTransition'
import { AnimatePresence, motion, Transition } from 'framer-motion'
import classnames from 'classnames'
import { Button } from './Button'
import { useOutsideClick } from './useOutsideClick'
import { UIProps } from '../types'
import styles from './Modal.module.scss'
import './transitions.css'

export type ModalProps = UIProps & {
  open: boolean
  error?: boolean
  handleClose?: () => void
  modalRootId?: string
  TransitionProps?: Partial<Transition>
  showCloseButton?: boolean
}

export const Modal = ({
  open,
  error,
  handleClose,
  modalRootId = 'modal-root',
  className,
  children,
  TransitionProps,
  showCloseButton,
}: ModalProps): React.ReactPortal | null => {
  const containerRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const pageWrapper = document.getElementById('page-wrapper')

    if (!pageWrapper || !pageWrapper.parentNode || !pageWrapper.parentNode.style) {
      return
    }

    if (open) {
      pageWrapper.parentNode.style.overflow = 'hidden'
    } else {
      pageWrapper.parentNode.style.overflow = 'auto'
    }

    return () => {
      if (!pageWrapper || !pageWrapper.parentNode || !pageWrapper.parentNode.style) {
        return
      }

      pageWrapper.parentNode.style.overflow = 'auto'
    }
  }, [])

  useOutsideClick(modalRef, handleClose)

  if (typeof document === 'undefined') {
    return null
  }

  if (!document.getElementById(modalRootId)) {
    const modalRoot = document.createElement('div')
    modalRoot.setAttribute('id', modalRootId)
    document.body.appendChild(modalRoot)
  }

  // TODO: fix framer-motion
  return ReactDOM.createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          ref={containerRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          layout
          id="modal-container"
          className={classnames('modal-container', styles['modal-container'])}
        >
          <div
            ref={modalRef}
            id="modal"
            className={classnames(
              'modal',
              styles.modal,
              error ? styles.error : undefined,
              className
            )}
          >
            {children}
            {showCloseButton &&
              <Button variant={'unstyled'} onClick={handleClose} className={styles.closeBtn} data-test="modal-close-button">
                <XSVG />
              </Button>
            }
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById(modalRootId) as Element
  )
}

const XSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g fill="none" fillRule="evenodd" stroke="#D8D8D8" strokeLinecap="square" strokeWidth="2">
      <path d="m1.417 1.417 13.806 13.806M15.583 1.417 1.777 15.223"></path>
    </g>
  </svg>
)
