'use client'

// https://stackoverflow.com/questions/48048957/react-long-press-event
import {
  MouseEvent,
  MouseEventHandler,
  TouchEvent,
  TouchEventHandler,
  useCallback,
  useRef,
} from 'react'
import { isTouchEvent } from '../util'

export function useLongPress(
  onLongPress: (event: MouseEvent | TouchEvent) => void,
  onClick?: (event: MouseEvent | TouchEvent) => void,
  { shouldPreventDefault = true, delay = 300 } = {},
): {
    onMouseDown: MouseEventHandler
    onMouseUp: MouseEventHandler
    onMouseLeave: MouseEventHandler
    onTouchStart: TouchEventHandler
    onTouchEnd: TouchEventHandler
  } {
  const longPressTriggered = useRef(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const target = useRef<EventTarget>()

  const start = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault)
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        longPressTriggered.current = true
      }, delay)
    },
    [onLongPress, delay, shouldPreventDefault],
  )

  const clear = useCallback(
    (event: MouseEvent | TouchEvent, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick?.(event)
      longPressTriggered.current = false
      if (shouldPreventDefault && target.current) {
        // @ts-ignore prevent default event
        target.current.removeEventListener('touchend', preventDefault)
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  )

  return {
    onMouseDown: start,
    onMouseUp: clear,
    onMouseLeave: (e) => clear(e, false),
    onTouchStart: start,
    onTouchEnd: clear,
  }
}

const preventDefault: EventListener = (event) => {
  if (isTouchEvent(event)) {
    event.preventDefault()
  }
}
