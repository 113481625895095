'use client'

import { useEffect } from 'react'

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback?: () => void
) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback?.()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [ref, callback])
}
