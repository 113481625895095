'use client'

// React-specific imports
import React, { MouseEvent, useEffect, useRef, useState } from 'react'

// React Hook Form-specific imports
import { UseFormReturn } from 'react-hook-form'

// Classnames
import classnames from 'classnames'

import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RawDraftContentState,
  RichUtils,
  SelectionState,
} from 'draft-js'

import 'draft-js/dist/Draft.css'
import { Button } from './Button'
import { Flex } from './Flex'
import styles from './RichTextEditor.module.scss'

const UL_FULL_REGEX = /^(\*|-)$/
const OL_FULL_REGEX = /^\d+\.$/

const commands = {
  OL: 'autolist-ordered',
  UL: 'autolist-unordered',
}

const blockTypes = {
  UL: 'unordered-list-item',
  OL: 'ordered-list-item',
  UNSTYLED: 'unstyled',
}

const inlineStyles = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
}

export type RichTextEditorProps = {
  className?: string
  name?: string
  value?: string
  placeholder?: string
  readOnly?: boolean
  onChange?: (value: string | RawDraftContentState) => void
  formProps?: Partial<UseFormReturn<any>>
  style?: React.CSSProperties
}

export const RichTextEditor = ({
  className,
  name = '',
  value = '',
  placeholder,
  readOnly,
  onChange,
  formProps: { register, unregister, setValue } = {},
  style,
  ...rest
}: RichTextEditorProps) => {
  const [editorState, setEditorState] = useState(
    RichTextEditor.convertToEditorState(value)
  )
  const [history, setHistory] = useState<string[]>([])
  const setTimeoutId = useState(0)[1]

  const contentState = editorState.getCurrentContent()
  const previousHasText = useRef(contentState.hasText())

  useEffect(() => {
    register?.(name)
    setValue?.(name, value)
    onChange?.(value)

    return () => unregister?.(name)
  }, [])

  useEffect(() => {
    if (previousHasText.current && !contentState.hasText()) {
      const newEditorState = RichTextEditor.convertToEditorState(value)
      setEditorState(EditorState.moveFocusToEnd(newEditorState))
    }
    previousHasText.current = contentState.hasText()
  },[value])

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (!initialized) {
      setInitialized(true)
    }
  }, [])

  if (readOnly) {
    if (!initialized) {
      return <></>
    }

    return contentState.hasText()
      ? <div
        className={classnames(
          'RichTextEditor-Container',
          styles.editorContainer,
          readOnly && styles.readOnly,
          className
        )}
        style={style}
        {...rest}
      >
        <Editor
          onChange={(): undefined => undefined}
          editorState={editorState}
          placeholder={placeholder || 'Add a description'}
          readOnly={readOnly}
        />
      </div>
      : <></>
  }

  const updateFormValue = (contentState: ContentState) => (): void => {
    const stringified = JSON.stringify(convertToRaw(contentState))

    setValue?.(name, stringified)
    onChange?.(stringified)
  }

  const handleOnChange = (editorState: EditorState): void => {
    if (typeof window !== 'undefined') {
      const contentState = editorState.getCurrentContent()
      const timeoutId = window.setTimeout(updateFormValue(contentState), 600)

      setTimeoutId((prevId: number) => {
        window.clearTimeout(prevId)
        return timeoutId
      })

      setEditorState(editorState)
    }
  }

  const keyBindingFn = (e: React.KeyboardEvent<unknown>): string | null => {
    if (e.keyCode === 32) {
      if (OL_FULL_REGEX.test(history.slice(-2).join(''))) {
        return commands.OL
      }
      if (UL_FULL_REGEX.test(history.slice(-1).join(''))) {
        return commands.UL
      }
    }
    // @ts-ignore - can't import their renaming of React.KeyboardEvent
    return getDefaultKeyBinding(e)
  }

  const handleKeyCommand = (
    command: string,
    editorState: EditorState
  ): DraftHandleValue => {
    if (command === commands.OL || command === commands.UL) {
      const listType = command === commands.UL ? blockTypes.UL : blockTypes.OL
      const listTest =
        command === commands.UL
          ? (text: string): boolean => UL_FULL_REGEX.test(text)
          : (text: string): boolean => OL_FULL_REGEX.test(text)

      let content = editorState.getCurrentContent()
      const selection = editorState.getSelection()
      const blockKey = selection.getStartKey()
      let block = content.getBlockForKey(blockKey)

      const blockText = block.getText()

      if (
        block.getType() === 'unstyled' &&
        block.getDepth() === 0 &&
        listTest(blockText)
      ) {
        editorState = RichUtils.toggleBlockType(editorState, listType)
        content = editorState.getCurrentContent()
        block = content.getBlockForKey(blockKey)

        const blockSelection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: 0,
          focusKey: blockKey,
          focusOffset: block.getLength(),
        })

        content = Modifier.replaceText(content, blockSelection, '')
      } else {
        content = Modifier.insertText(content, selection, ' ')
      }

      editorState = EditorState.push(editorState, content, 'change-block-type')
      editorState = EditorState.forceSelection(
        editorState,
        content.getSelectionAfter()
      )
      handleOnChange(editorState)
      return 'handled'
    }

    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      handleOnChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const handleControl =
    (type: string) =>
      (e: MouseEvent): void => {
        e.preventDefault()
        switch (type) {
          case inlineStyles.BOLD:
          case inlineStyles.ITALIC:
          case inlineStyles.UNDERLINE:
            handleOnChange(
              RichUtils.toggleInlineStyle(editorState, type.toUpperCase())
            )
            break
          case blockTypes.UL:
          case blockTypes.OL:
            handleOnChange(RichUtils.toggleBlockType(editorState, type))
            break
        }
      }

  let hidePlaceholder = false
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hidePlaceholder = true
    }
  }

  return (
    <div
      className={classnames(
        'RichTextEditor-Container',
        styles.editorParentContainer,
        className
      )}
      {...rest}
    >
      <Flex
        alignItems="stretch"
        pl={1}
        className={classnames('RichTextEditor-Controls', styles.editorControls)}
      >
        <Button
          aria-label="rich text editor bold button"
          type="button"
          variant="unstyled"
          className={styles.editorIconButton}
          onClick={handleControl(inlineStyles.BOLD)}
        >
          <BoldSVG />
        </Button>

        <Button
          aria-label="rich text editor italicize button"
          type="button"
          variant="unstyled"
          className={styles.editorIconButton}
          onClick={handleControl(inlineStyles.ITALIC)}
        >
          <ItalicSVG />
        </Button>

        <Button
          aria-label="rich text editor underline button"
          type="button"
          variant="unstyled"
          className={styles.editorIconButton}
          onClick={handleControl(inlineStyles.UNDERLINE)}
        >
          <UnderlineSVG />
        </Button>

        <Button
          aria-label="rich text editor unordered list button"
          type="button"
          variant="unstyled"
          className={styles.editorIconButton}
          onClick={handleControl(blockTypes.UL)}
        >
          <UnorderedListSVG />
        </Button>

        <Button
          aria-label="rich text editor ordered list button"
          type="button"
          variant="unstyled"
          className={styles.editorIconButton}
          onClick={handleControl(blockTypes.OL)}
        >
          <OrderedListSVG />
        </Button>
      </Flex>

      <div
        className={classnames(
          'RichTextEditor-Editor',
          styles.editorContainer,
          hidePlaceholder && styles.hidePlaceholder
        )}
      >
        {initialized && (
          <Editor
            editorState={editorState}
            onChange={handleOnChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
            placeholder={placeholder || 'Add a description'}
          />
        )}
      </div>
    </div>
  )
}

RichTextEditor.convertToEditorState = (value: string): EditorState => {
  let data

  if (!value) {
    return EditorState.createEmpty()
  }

  try {
    data = convertFromRaw(JSON.parse(value))
  } catch (e) {
    try {
      data = ContentState.createFromText(value)
    } catch (e) {
      data = ContentState.createFromText('')
    }
  }
  return EditorState.createWithContent(data)
}

RichTextEditor.convertRawToString = (raw: string) => {
  return convertFromRaw(JSON.parse(raw)).getPlainText()
}

export const BoldSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="11"
    viewBox="0 0 9 11"
  >
    <path
      fill="#017ACC"
      d="M19.7464,12.0464 C20.3176029,12.0464 20.8467976,12.0883996 21.334,12.1724 C21.8212024,12.2564004 22.2467982,12.4047989 22.6108,12.6176 C22.9748018,12.8304011 23.260399,13.1103983 23.4676,13.4576 C23.674801,13.8048017 23.7784,14.2415974 23.7784,14.768 C23.7784,15.0144012 23.7448003,15.2607988 23.6776,15.5072 C23.6103997,15.7536012 23.5152006,15.9831989 23.392,16.196 C23.2687994,16.4088011 23.1204009,16.5991992 22.9468,16.7672 C22.7731991,16.9352008 22.5744011,17.0583996 22.3504,17.1368 L22.3504,17.204 C22.6304014,17.2712003 22.8879988,17.3747993 23.1232,17.5148 C23.3584012,17.6548007 23.5655991,17.8311989 23.7448,18.044 C23.9240009,18.2568011 24.0639995,18.5059986 24.1648,18.7916 C24.2656005,19.0772014 24.316,19.4047982 24.316,19.7744 C24.316,20.3344028 24.2068011,20.815998 23.9884,21.2192 C23.7699989,21.622402 23.4676019,21.9555987 23.0812,22.2188 C22.6947981,22.4820013 22.2440026,22.6779994 21.7288,22.8068 C21.2135974,22.9356006 20.659203,23 20.0656,23 L16,23 L16,12.0464 L19.7464,12.0464 Z M19.864,18.2624 L18.4696,18.2624 L18.4696,21.0848 L19.864,21.0848 C21.2192068,21.0848 21.8968,20.5920049 21.8968,19.6064 C21.8968,19.1247976 21.7288017,18.780401 21.3928,18.5732 C21.0567983,18.365999 20.5472034,18.2624 19.864,18.2624 L19.864,18.2624 Z M19.6456,13.9616 L18.4696,13.9616 L18.4696,16.4312 L19.6288,16.4312 C20.233603,16.4312 20.6731986,16.3136012 20.9476,16.0784 C21.2220014,15.8431988 21.3592,15.529602 21.3592,15.1376 C21.3592,14.7119979 21.2192014,14.4096009 20.9392,14.2304 C20.6591986,14.0511991 20.2280029,13.9616 19.6456,13.9616 Z"
      opacity=".796"
      transform="translate(-16 -12)"
    />
  </svg>
)

export const ItalicSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="11"
    viewBox="0 0 5 11"
  >
    <polygon
      fill="#017ACC"
      points="50.177 23 52.378 12.046 54.679 12.046 52.495 23"
      opacity=".796"
      transform="translate(-50 -12)"
    />
  </svg>
)

export const UnderlineSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 11 13"
  >
    <g
      fill="#017ACC"
      strokeLinecap="round"
      opacity=".796"
      transform="translate(.706 .046)"
    >
      <path d="M4.6112,11.1552 C3.18879289,11.1552 2.10240375,10.7352042 1.352,9.8952 C0.601596248,9.0551958 0.2264,7.71680918 0.2264,5.88 L0.2264,7.46069873e-14 L2.7128,7.46069873e-14 L2.7128,6.132 C2.7128,7.20720538 2.87519838,7.95759787 3.2,8.3832 C3.52480162,8.80880213 3.99519692,9.0216 4.6112,9.0216 C5.22720308,9.0216 5.70319832,8.80880213 6.0392,8.3832 C6.37520168,7.95759787 6.5432,7.20720538 6.5432,6.132 L6.5432,7.46069873e-14 L8.9288,7.46069873e-14 L8.9288,5.88 C8.9288,7.71680918 8.56200367,9.0551958 7.8284,9.8952 C7.09479633,10.7352042 6.02240706,11.1552 4.6112,11.1552 Z" />
      <polygon points="0 12.214 0 11.374 9.966 11.374 9.966 12.214" />
    </g>
  </svg>
)

export const OrderedListSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="14"
    viewBox="0 0 17 14"
  >
    <path
      fill="#017ACC"
      d="M.336 5.334L.336 4.3344 1.4952 4.3344 1.4952 1.2516.4956 1.2516.4956.4872C.786801456.43119972 1.03319899.364000392 1.2348.2856 1.43640101.207199608 1.63239905.11200056 1.8228-8.34887715e-14L2.73-8.34887715e-14 2.73 4.3344 3.7296 4.3344 3.7296 5.334.336 5.334zM.0588 13.134L.0588 12.4284.54915011 11.9653499C.70524972 11.8162503.853999293 11.6724007.9954 11.5338 1.27820141 11.2565986 1.52179898 10.9962012 1.7262 10.7526 1.93060102 10.5089988 2.09019943 10.2822011 2.205 10.0722 2.31980057 9.86219895 2.3772 9.66200095 2.3772 9.4716 2.3772 9.20839868 2.31000067 9.00820069 2.1756 8.871 2.04119933 8.73379931 1.85640118 8.6652 1.6212 8.6652 1.42519902 8.6652 1.25160076 8.71979945 1.1004 8.829.949199244 8.93820055.806400672 9.06559927.672 9.2112L6.67688127e-13 8.5476C.263201316 8.2675986.531998628 8.05620071.8064 7.9134 1.08080137 7.77059929 1.4083981 7.6992 1.7892 7.6992 2.05240132 7.6992 2.29179892 7.73979959 2.5074 7.821 2.72300108 7.90220041 2.90919922 8.01699926 3.066 8.1654 3.22280078 8.31380074 3.34319958 8.49159896 3.4272 8.6988 3.51120042 8.90600104 3.5532 9.13839871 3.5532 9.396 3.5532 9.62000112 3.50560048 9.84819884 3.4104 10.0806 3.31519952 10.3130012 3.1878008 10.5453988 3.0282 10.7778 2.8685992 11.0102012 2.68660102 11.2425988 2.4822 11.475 2.27779898 11.7074012 2.06640109 11.9355989 1.848 12.1596L2.13733312 12.1278667 2.13733312 12.1278667 2.2974 12.1134C2.46260083 12.0993999 2.60959936 12.0924 2.7384 12.0924L3.8136 12.0924 3.8136 13.134.0588 13.134zM6.048 11.434L15.848 11.434C16.2345993 11.434 16.548 11.1205993 16.548 10.734 16.548 10.3474007 16.2345993 10.034 15.848 10.034L6.048 10.034C5.66140068 10.034 5.348 10.3474007 5.348 10.734 5.348 11.1205993 5.66140068 11.434 6.048 11.434zM6.048 3.634L15.848 3.634C16.2345993 3.634 16.548 3.32059932 16.548 2.934 16.548 2.54740068 16.2345993 2.234 15.848 2.234L6.048 2.234C5.66140068 2.234 5.348 2.54740068 5.348 2.934 5.348 3.32059932 5.66140068 3.634 6.048 3.634z"
      opacity=".796"
      transform="translate(.158 .666)"
    />
  </svg>
)

export const UnorderedListSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="11"
    viewBox="0 0 17 11"
  >
    <g
      fill="#017ACC"
      fillRule="evenodd"
      strokeLinecap="round"
      opacity=".796"
      transform="translate(.706 .2)"
    >
      <circle cx="1.4" cy="1.4" r="1.4" />
      <circle cx="1.4" cy="9.2" r="1.4" />
      <path
        fillRule="nonzero"
        d="M5.6 9.9L15.4 9.9C15.7865993 9.9 16.1 9.58659932 16.1 9.2 16.1 8.81340068 15.7865993 8.5 15.4 8.5L5.6 8.5C5.21340068 8.5 4.9 8.81340068 4.9 9.2 4.9 9.58659932 5.21340068 9.9 5.6 9.9zM5.6 2.1L15.4 2.1C15.7865993 2.1 16.1 1.78659932 16.1 1.4 16.1 1.01340068 15.7865993.7 15.4.7L5.6.7C5.21340068.7 4.9 1.01340068 4.9 1.4 4.9 1.78659932 5.21340068 2.1 5.6 2.1z"
      />
    </g>
  </svg>
)
